import Head from "next/head"

const poppingsFontUrl = 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800&display=swap'

const MetaHead = () => {
  return (
    <Head>
      <link rel='stylesheet' href={poppingsFontUrl} />
    </Head>
  )
};

export default MetaHead;
