import NextHead from 'next/head'

const ganalyticsId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID
const ganalyticsSrc = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${ganalyticsId}');
`

const AnalyticsHead = () => {
  return (
    <NextHead>
				<script async src={`https://www.googletagmanager.com/gtag/js?id=${ganalyticsId}`}/>
				<script dangerouslySetInnerHTML={{ __html: ganalyticsSrc }} />
    </NextHead>
  )
}
export default AnalyticsHead