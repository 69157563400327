
import store from '../store'
import { Provider as ReduxProvider } from 'react-redux'

import { ThemeProvider as LandingThemeProvider } from 'styled-components'
import { extendTheme, ChakraProvider} from '@chakra-ui/react'

import { GlobalStyle as AppStyle } from '../styles/app.style'
import { GlobalStyle as LandingStyle } from '../styles/landing.style'
import theme from '../styles/themeStyles'

import AnalyticsHead from "../components/Head/Analytics"
import FaviconHead from "../components/Head/Favicon"
import FontHead from "../components/Head/Font"

import SEO from "../components/Head/Social" 

import { Box } from '@chakra-ui/react'

const title = "Diagram - The most complete Nocode API builder"

if (!global.setImmediate) {
  global.setImmediate = setTimeout
}
const themeChakra = extendTheme({
  components: {
    Button: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
    CheckBox: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
  },
  colors: {
    brand: {
      500: "#333333",
      600: "#404040",
    },
  },
})

const App = ({ Component, pageProps, router }) => {
  if (router.route.startsWith('/app')) {
    return (
      <ReduxProvider store={store}>
        <ChakraProvider theme={themeChakra}>
          <Box flex={1} height="100%">
            <SEO title={title}/>
            <AppStyle/>
            <FaviconHead/>
            <AnalyticsHead/>
            <Component {...pageProps} />
          </Box>
        </ChakraProvider>
      </ReduxProvider>
    )
  } else {
    return (
      <LandingThemeProvider theme={theme}>
        <Box>
          <LandingStyle />
          <AnalyticsHead/>
          <FaviconHead/>
          <FontHead/>
          <Component {...pageProps} />
        </Box>
      </LandingThemeProvider>
    )
  }
}

export default App
