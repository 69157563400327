import { createGlobalStyle } from 'styled-components'; 

 

const GlobalStyle = createGlobalStyle`

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

#__next {
  height: 100%;
  // display: flex;
}

div[role=dialog] {
  animation-duration: 0s !important;
}

/* To be cleaned */

.hover-opacity:hover {
  opacity: 0.8;
}

.hover-background:hover {
  background-color: #f7f7f7;
}

.hoverable-opacity:hover {
  cursor: pointer;
  opacity: 0.5;
}

.hoverable:hover {
  cursor: pointer;
}

/* To be removed */

.diagram-container {
  height: 100vh;
}

.diagram-container-2 {
  flex: 1;
}

/* Fixes */

.button-clear:hover {
  background-image: none !important;
}

.button-clear:focus {
  background-image: none !important;
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14), inset 0 -1px 1px 0 rgba(67, 90, 111, 0.06) !important;
}

.button-icon:hover {
  opacity: 0.3;
  transition-duration: 0.5s !important;
}

.non-hoverable-active:hover {
  color: white;
  background-color: rgb(51, 51, 51);
}

.non-hoverable:hover {
  cursor: unset;
}






/* Prototype */

.colored {
  color: #800080;
}

.disabled-custom:disabled {
  background-image: linear-gradient(to bottom, #23C277, #399D6C) !important;
  color: white !important;
  pointer-events: auto !important;
}

.hide-after-3-seconds {
  -webkit-animation: css-animation 0s ease-in 3s forwards;
  animation: css-animation 0s ease-in 3s forwards;
  padding: 0;
}
@keyframes css-animation {
  from {
    opacity: 1;
    width: 100%;
  }
  to {
      opacity: 0;
      width: 0;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadein_08 {
  from { opacity: 0; }
  to   { opacity: 0.8; }
}
`


export { GlobalStyle };
