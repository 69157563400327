import Head from "next/head"

import FavIcon from "../../assets/favicon.png"

const MetaHead = () => {
  return (
    <Head>
      <link rel="shortcut icon" type="image/x-icon" href={FavIcon} />
    </Head>
  )
};

export default MetaHead;
